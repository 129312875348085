import React, { Component } from 'react';
import '../App.css';
import axios, { Axios } from 'axios';
import { Link, useParams } from 'react-router-dom';
import Headermain from './Headermain';
import jwtDecode from 'jwt-decode';

class OtpPhone extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      selectValue: '+91',
    };
  }


  componentDidMount() {
   // const token = localStorage.getItem("token");
    
    //const ru = jwtDecode(token);
    //console.log('test=>'+ru);
    //const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    // if(exp_time>3600)
    // {
    //   window.location.href = "/user-logout";
    // }
    const params = this.props.params;
    axios
      .get('/api/users/get-user/'+params.id)
      .then(res => {
        
        console.log(res.data.otp_verified);
        if(res.data.otp_verified=="1")
        {
        window.location.href = "/create-request"; 
        }
      })
      .catch(err => {
        //alert(err.data.msg);
        alert('error');
        console.log("Error in CreateBook!");
      })   
  
  
};

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange=(e)=>{
    this.setState({selectValue:e.target.value});
  };

  handleCheckboxChange = (event) => {
    this.setState({ sms_updates: event.target.checked });
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }
  };
  onSubmit = e => {
    e.preventDefault();
    //alert(this.state.sms_updates);
    let sms_update;
    if(this.state.sms_updates==undefined || this.state.sms_updates == false)
    {
     //sms_update = 'no';
     sms_update = 'na';
    }
    else
    {
      //sms_update="yes";
      sms_update = 'na'; 
    }
    const params = this.props.params;
    const data = {
      phoneNumber: this.state.phone,
      countryCode:this.state.selectValue,
      sms_updates:sms_update,
      id:params.id
    };

    axios
      .post('/api/sms/send-otp',data)
      .then(res => {
        this.setState({
          phone: ''
        });
        if(res.status==200)
        {
        alert(res.data.msg);
        window.location.href = "/otp-verify/"+res.data.id+"/"+res.data.phoneNumber; 
        }
        else if (res.status==202)
        {
        alert(res.data.msg);
        //console.log(res.data);
        }
      })
      .catch(err => {
        //alert(err.data.msg);
        alert('error');
        console.log("Error in CreateBook!");
      })
  };

  

  render() {
    
    
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/
    return (

      <div>
      <Headermain/>
      
      <div className='col-md-12 banner-header'><h1 className="lang">User Phone Number</h1></div>
      <div className="middle-section">
        <div className="middle">
        <div className="row">
          <div className="col-md-5 left_section">
            <div className="content-left">
            
<h3 className='title lang'>Verify Your Phone Number!</h3>

          </div></div>
          <div className="col-md-7 right_section">
          <div className="content-right">
          <h3 className="lang">Enter Your Phone Number<br/>(For Verification Purposes Only)</h3>

<div className="form-register form-register11">
<form noValidate onSubmit={this.onSubmit}>
<div className="phoneOtp">
<select className='countryCode'  value={this.state.selectValue} onChange={this.handleChange}>

       <option value="+91">IN</option>
        <option value="+1">CA</option>
        {/* <option value="+49">DE</option> */}
          </select>
<input className='phoneNumber' type="text" name="phone" placeholder="Phone" value={this.state.phone} onChange={this.onChange}/>
</div>
{/* <div className="row get_sms">
<div className='col-md-1'>
<input type="checkbox" onChange={this.handleCheckboxChange} name="sms_updates" className="form-check-input input_checkbox" value="yes" />
</div>
<div className='col-md-11 '>
<label className="form-check-label" >Get Sms Updates?</label>
    </div>            
</div> */}
<input name="submit" value="Send me my verification code" type="submit" className="button-nav"/>
</form>

</div>

            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default () => {
  const params = useParams();
  //console.log(params);
return (
    <OtpPhone params={params} />
)
}