import React, { Component } from "react";
import "../../App.css";
import "../../Style.css";
import "../../script.js";
import { Link, Navigate } from "react-router-dom";
import Footer from "../Footer";
import axios from "axios";
import jwtDecode from "jwt-decode";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homes: {},
      services: [],
      adminName: "no",
      redirect: false
    };
  }

  componentDidMount() {
    const lang = localStorage.getItem("lang");
    if (lang === "fr") {
      this.setState({ redirect: true });
    }

    axios
      .get("/api/pages")
      .then(res => this.setState({ homes: res.data }))
      .catch(err => console.log("Error fetching pages:", err));

    axios
      .get("/api/services")
      .then(res => this.setState({ services: res.data }))
      .catch(err => console.log("Error fetching services:", err));

    const token = localStorage.getItem("admin_token");
    if (token) {
      this.setState({ adminName: localStorage.getItem("admin_name") });
      const user = jwtDecode(token);
      console.log("Admin ID:", user.id);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/fr" />;
    }

    const { homes, services } = this.state;

    return (
      <>
        <div className="top-banner">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img src="/img/logo-final.png" width="90" alt="Logo" />
                </a>
                {/* Bootstrap Default Toggle Button */}
                <button 
                  className="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                {/* Bootstrap Default Menu */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="nav-item">
                      <Link className="nav-link lang" to="/login">User Login</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="lang nav-link" to="/login-as-professional">Professional Login</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="lang nav-link" to="/professional">Join as a Professional</Link>
                    </li>
                    <li className="nav-item">
                      <button className="en lan" data-id="en"><img src="/en.png" alt="English" /></button>
                      <button className="fr lan" data-id="fr"><img src="/fr.png" alt="French" /></button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="center-div">
          <h2><span>{homes.banner_h1}</span></h2>
          <h1><span>{homes.banner_h2}</span></h1>
          <h3><span>{homes.banner_h3}</span></h3>
          <a href={homes.banner_link} className="btn btn-info">Get Help Now</a>
        </div>
        <div className="banner-bottom">
          <div className="container">
            <div className="row">
              {["icon1.png", "icon2.png", "icon3.png", "icon4.png"].map((icon, index) => (
                <div className="col-md-3" key={index}>
                  <div className="icon-div">
                    <img src={`img/${icon}`} alt="Feature Icon" />
                    <h4>{["Professional Services", "Instant Quote", "24/7 Availability", "Experienced & Reliable"][index]}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container">
            <h2>{homes.services_heading}</h2>
            <h5>{homes.services_description}</h5>
            <div className="row">
              {services.map((service, index) => (
                <div className="col-md-3" key={index}>
                  <div className="service">
                    <img src={`img/${service.icon}`} alt={service.name} />
                    <h6>{service.name}</h6>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mid-section">
<div className="container">
<div className="row">
<div className="col-md-8">
<div className="content">
<h3>{homes.section_1_heading}</h3>
<p>
{homes.section_1_description}
</p>
<a href={homes.section_1_link} className="btn btn-info">Get Started</a>
</div>	
</div>
<div className="col-md-4">
<div className="img">
<img src="img/img.png"/>	
</div>
</div>	
</div>
</div>	
</div>
<div className="services customer_satisfaction">
<div className="container">
<h2>Your Satisfaction, Our Commitment</h2>
<h5>Don't let unexpected emergencies at home or in the workplace disrupt your peace of mind. Trust SOS BLAST to quickly connect you with qualified professionals who care about your comfort and safety.</h5>
{/* <div className="row">
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer1.png"/>
<h1>300+</h1>
<p>Verfied Professionals</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer2.png"/>
<h1>3500</h1>
<p>Job Successfully Completed</p>
</div>
</div>
<div className="col-md-4">
<div className="service customer">
<img src="img/consumer3.png"/>
<h1>98%</h1>
<p>Customer<br/>Satisfaction</p>
</div>
</div>
</div> */}
<div className="row">
    <div className="container mx-auto py-12">
        {/* <h2 class="text-3xl font-bold text-center mb-8">Obtenez votre adhésion</h2> */}
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold">Premium Membership</h3>
            <p className="text-gray-600 mt-2">Unlock All Exclusive Features</p>
            <p className="text-3xl font-bold mt-4">€29.99/month</p>
            <ul className="mt-4 space-y-2 text-gray-700">
                <li>&#10003; Unlimited Access</li>
                <li>&#10003; Premium Content</li>
                {/* <li>&#10003; Support 24/7</li> */}
            </ul>
            <button className="mt-6 bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300 btn btn-info">
            Buy Now
            </button>
        </div>
    </div>
</div>

</div>
</div>
        <Footer />
      </>
    );
  }
}

export default Home;
