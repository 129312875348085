import React, { Component } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const withMembershipCheck = (WrappedComponent) => {
    return class extends Component {
        componentDidMount() {
            const token = localStorage.getItem("professional-token");
            const ru = jwtDecode(token);

            // Check token expiration
            const exp_time = (Math.floor(Date.now() / 1000)) - ru.iat;
            if (exp_time > 3600) {
                window.location.href = "/professional-logout";
                return;
            }

            // Fetch professional data
            axios.get('api/professionals/get-professional/' + token)
                .then(res => {
                    if (res.data.membershipStatus !== "active") {
                        window.location.href = "/membership-purchase";
                    }
                })
                .catch(err => {
                    console.log('Error fetching professional data:', err);
                });
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withMembershipCheck;