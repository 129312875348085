import React, { Component } from 'react';
import '../../App.css';
import '../../Style.css';
import '../../script.js';
import { Link} from 'react-router-dom';
import axios from 'axios';
import Footer from '../Footer';
import jwtDecode from 'jwt-decode';

class Phome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homes: [],
      services:[],
      proName:'no'
    };
  }



  componentDidMount() {

    if(localStorage.getItem("lang"))
    {
      if(localStorage.getItem("lang")==='fr')
      {
       //alert(localStorage.getItem("lang"));
       window.location.href = "/fr/professional";
      }
    }

    const token = localStorage.getItem("professional-token");
    if(token!=null)
    {
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }
    else
    {
      window.location.href = "/professional-dashboard";
    }
    }
    
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
       this.setState({proName:localStorage.getItem("p_name")});

               
    //    if(res.data.otp_verified=="0" || res.data.otp_verified==undefined)
    //       {
    //       window.location.href = "/professional-phone-otp/"+ru.id; 
    //       }
    // if(res.data.status==2)
    // {
    // window.location.href="/disapproved-professional";
    // }
    // else if(res.data.status==0)
    // {
    // window.location.href='/waiting-for-approval'; 
    // }
    // else if(res.data.status==3)
    // {
    // window.location.href='/professional-profile'; 
    // }
    })
    .catch(err =>{
      console.log('Error from professional');
    })
    

    
    


    axios
      .get('/api/pages/professional-page')
      .then(res => {
        console.log("Print-showapplicationDetails-API-response: " + JSON.stringify(res.data));
        this.setState({
          homes: res.data
        })
      
         
      })
      .catch(err => {
        console.log("Error from viewAppDetails");
      })

      axios
        .get('/api/services')
        .then(res => {
          console.log("Print-services-API-response: " + JSON.stringify(res.data));
          this.setState({
            services: res.data
          })
        })
        .catch(err => {
          console.log("Error from viewAppDetails");
        })
  };
  
   
  render() {
    
const homes = this.state.homes;
const services = this.state.services;
console.log('this=>'+homes);
var data = homes.section_1_description;
var data1 = homes.banner_bottom_heading;
var data2 = homes.services_description;
if(data!==undefined)
{
console.log(data);
//alert(data);
const decodedData = data.replace(/\n/g, '<br>');
// Display 'decodedData' in your HTML using innerHTML
document.getElementById('content_description').innerHTML = decodedData;
document.getElementById('banner_bottom_heading').innerHTML = data1.replace(/\n/g, '<br>');
document.getElementById('services_description').innerHTML = data2.replace(/\n/g, '<br>');
}





    return (
      <>
      
<div className="top-banner top-banner-professional">
<div className="container">
<div className="row">
<div className="col-md-12">
<nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img src="/img/logo-final.png" width="90" alt="Logo" />
                </a>
                {/* Bootstrap Default Toggle Button */}
                <button 
                  className="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                {/* Bootstrap Default Menu */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="nav-item">
                      <Link className="nav-link lang" to="/login">User Login</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="lang nav-link" to="/login-as-professional">Professional Login</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="lang nav-link" to="/professional">Join as a Professional</Link>
                    </li>
                    <li className="nav-item">
                      <button className="en lan" data-id="en"><img src="/en.png" alt="English" /></button>
                      <button className="fr lan" data-id="fr"><img src="/fr.png" alt="French" /></button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
	
	
</div>

	</div>
</div>
<div className="center-div">
<h1><span>{homes.banner_h1}</span></h1>
<h3>
        <span>{homes.banner_h3}</span>
      </h3>
<a href={homes.banner_link} className="btn btn-info btn-prof">Get Started</a>
</div>
</div>
<div className="banner-bottom banner-bottom-prof">
<div className="container">
<div className="row">
<div className="col-md-12">
<h2 id="banner_bottom_heading" dangerouslySetInnerHTML={{ __html: homes.banner_bottom_heading }} />
{/*<a href={homes.banner_bottom_link} className="btn btn-info btn-prof">Register Now</a>*/}
</div>
</div>
</div>	
</div>
{/*<div className="how_we_work">
<div className="container">
<h2>
        <span>{homes.how_heading}</span>
      </h2>
<h5>
        <span>{homes.how_description}</span>
      </h5>

</div>	
</div>
*/}
<div className="mid-section  mid-section1">
<div className="container">
<div className="row">
<div className="col-md-12"><h3>{homes.section_1_heading}</h3></div>
<div className="col-md-12">
<div className="content">
<p id="content_description">

</p>
{/*<a href={homes.section_1_link} className="btn btn-info btn-prof">Start Registration</a> */}
</div>	
</div>
{/* <div className="col-md-6">
<div className="img">
<img src="img/mid-section.png"/>	
</div>
</div>	 */}
</div>
</div>	
</div>
<div className="services services1">
<div className="container">
<h2>{homes.services_heading}</h2>
<h5 id="services_description">{homes.services_description}</h5>
<div className="row">
{services.map(service => {
          return <div className="col-md-3">
          <div className="service service1">
            <img src={`img/${service.icon}`}/>
            <h6>{service.name}</h6>
            </div></div>
        })}





</div>
</div>
</div>

<div className="services customer_satisfaction customer_satisfaction1">
<div className="container">
<h2>How to Get Started</h2>
<div className="row">
<div className="odd1 col-md-2">01</div><div className="odd2 col-md-10"><h5>Register:</h5><a href="https://sosblast.com/register-as-professional">Sign up on SOS BLAST</a> and complete your profile to highlight your expertise and credentials.</div>
<div className="even2 col-md-10"><h5>Set Your Preferences:</h5> Specify the types of emergencies you're equipped to handle and the areas you can serve.</div><div className="even1 col-md-2">02</div>
<div className="odd1 col-md-2">03</div><div className="odd2 col-md-10"><h5>Eligibility Check:</h5> Submit background and verification documentation (if applicable). This allows us to make our platform safe for all users.</div>
<div className="even2 col-md-10"><h5>Receive Alerts:</h5> Once your registration is approved, you'll receive real-time alerts for emergencies in your chosen field and location.</div><div className="even1 col-md-2">04</div>
<div className="odd1 col-md-2">05</div><div className="odd2 col-md-10"><h5>Engage with Clients:</h5> Respond to user inquiries promptly, providing quotes and estimated time frames.</div>
<div className="even2 col-md-10"><h5>Deliver Quality Service:</h5> Once chosen by a user, deliver the exceptional service you're known for and help them regain their peace of mind.</div><div className="even1 col-md-2">06</div>
</div><br/>
<div className="row">
    <div className="container mx-auto py-12">
        {/* <h2 class="text-3xl font-bold text-center mb-8">Obtenez votre adhésion</h2> */}
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold">Premium Membership</h3>
            <p className="text-gray-600 mt-2">Unlock All Exclusive Features</p>
            <p className="text-3xl font-bold mt-4">€29.99/month</p>
            <ul className="mt-4 space-y-2 text-gray-700">
                <li>&#10003; Unlimited Access</li>
                <li>&#10003; Premium Content</li>
                {/* <li>&#10003; Support 24/7</li> */}
            </ul>
            <button className="mt-6 bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300 btn btn-info">
            Buy Now
            </button>
        </div>
    </div>
</div>
</div>
</div>

<Footer/>

      </>
        
        
    );
  }
}

export default Phome;