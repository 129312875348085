import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import Headertop from './Headerprotop';

class ProfessionalApplyRequestForm extends Component {
  
  constructor() {
    
    //console.log(id);
    
    super();
    this.state = {
      time_of_service:'',
      fees:'',
      request_applied:'',
      professional_id:'',
      isSubmitted:false,
      lang:'en',
      standard_fees:''
    };
    
  }








  componentDidMount() {
    //alert('res.data.standard_fees');
    const token1 = localStorage.getItem("professional-token");
    axios.get('/api/professionals/get-professional/'+token1).then(res => {
      
        this.setState({
       
      standard_fees:res.data.standard_fees,
      fees:res.data.standard_fees
        });

        
        
      })
      .catch(err =>{
        console.log('Error from professional');
      })

    var lan = localStorage.getItem("lang");
  
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const token = localStorage.getItem("professional-token");
    const ru = jwtDecode(token);
    console.log('test=>'+ru);
    const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
    if(exp_time>3600)
    {
      window.location.href = "/professional-logout";
    }
    const params = this.props.params;
    console.log(params.id);
    axios
      .get('/api/requests/'+params.id)
      .then(res => {
        this.setState({
          reqqs: res.data
         })
         //console.log(res.data);
      })
      .catch(err =>{
        console.log('Error from ShowBookList');
      })
      
  };








  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    const token = localStorage.getItem("professional-token");
    const u = jwtDecode(token);
    //alert('clicked');
    e.preventDefault();
    const params = this.props.params;
    if(this.state.fees=='')
    {
     axios
     .get('/api/professionals/get-professional/'+token)
     .then(res=>{
     //alert(res.data.standard_fees);
     this.setState({standard_fees:res.data.standard_fees});
     const data = {
          
      time_of_service:this.state.time_of_service,
      fees:this.state.fees,
      request_applied:params.id,
      professional_id:u.id
  
};

axios
      .post('/api/requests/apply', data)
      .then(res => {
        this.setState({
          time_of_service:"",
          fees:"",
          request_applied:"",
          professional_id:"", 
          isSubmitted:true
        });
        //alert(res.data.msg);
        //window.location.href = "http://localhost:8082/request-applied"; 
      })
      .catch(err => {
        alert(err);
        console.log("Error in Creating request");
      })

     })
     .catch(err=>{
      alert('Unable to fetch professional details');
     })
    }
    
    else
    {
      const data = {
          
        time_of_service:this.state.time_of_service,
        fees:this.state.fees,
        request_applied:params.id,
        professional_id:u.id
    
  };
  
  axios
        .post('/api/requests/apply', data)
        .then(res => {
          this.setState({
            time_of_service:"",
            fees:"",
            request_applied:"",
            professional_id:"", 
            isSubmitted:true
          });
          //alert(res.data.msg);
          //window.location.href = "http://localhost:8082/request-applied"; 
        })
        .catch(err => {
          alert(err);
          console.log("Error in Creating request");
        })
    }
    
    
  };



  
  render() {
    //const { id } = this.props;
    
    //console.log('test=>'+JSON.stringify(this.props));
    const params = this.props.params;
    var lang=this.state.lang;
    return (
<div>
      <Headertop/>
	  <div className='container-fluid'>
      <div className='row'>
      <Header />
		<div className='col-md-10 menu-right'>
    { this.state.isSubmitted==false ?(
      <div className="dashboard-right">
        

        <form noValidate onSubmit={this.onSubmit} className="request form" encType="multipart/form-data">
          
          <div className="card-box">
            
            
            
            <div className="row">
              <div className="col-md-5 form-group">
                <label className="form-label lang">Estimate time of Service (in hours)</label>
                <input className="form-control" type="text" name="time_of_service" placeholder="Type Here" value={this.state.time_of_service} onChange={this.onChange} />
              </div>
              </div>
              <div className="row">
              <div className="col-md-5 form-group special">
                <label>
                <input type="checkbox" name="special_cost_select" className="special_cost_select form-checkbox"/>&nbsp;Surge Cost</label><div className="tooltip-container"><span className="question-mark">?</span><div className="tooltip">{lang=='fr'?(<>Pour la facturation</>):(<>This will replace the standard cost of {this.state.standard_fees}.</>)}</div></div>
                </div></div><br/>
                <div className="row">
              <div className="col-md-5 form-group special_cost">
                <label className="form-label lang">Fees Applicable</label>
                <input className="form-control" type="text" name="fees" placeholder="Type Here" value={this.state.fees} onChange={this.onChange} />
                <input type="hidden" name="professional_id" value=''/>
              </div>
            </div>
            
            <input className="button-nav" name="submit" value="Apply" type="submit"  />
          </div>
          
          
        </form>

      </div>
    ):(
      <div className="dashboard-right thanks_msg">
        
                <h3 className="lang">You have successfully applied for the request</h3>
<h5 className="lang"></h5>

          </div> 
    )}
</div>
</div>
</div>
</div>

      


    );
  }

  
}

export default () => {
  const params = useParams();
  console.log(params);
  return (
      <ProfessionalApplyRequestForm params={params} />
  )
}

//export default ProfessionalApplyRequestForm;