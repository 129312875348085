import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const UserReqApplied = (props) => {
    const  reqq  = props.reqq;

    return(
        <div className="card-container col-md-12">
           <div className="desc list_container">
                <h1 className="list_title">
                    <Link className="lang" to={`/application-list/${reqq._id}`}>
                        { reqq.repair_explanation }
                    </Link>
                    
                </h1>
                <p className="lang">{ new Date(reqq.createdAt).toLocaleString()}</p>
                <Link  to={`/application-list/${reqq._id}`} className="btn btn-info btn-right lang btn-size">
                        View
                    </Link>
            </div>

        </div>
    )
};

export default UserReqApplied;