import React, { Component } from 'react';
import '../App.css';
import axios, { Axios } from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Headertop from './Headeradmintop';
import jwtDecode from 'jwt-decode';

class DisapproveProfessional extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      d_reason:''
    };
  }

  componentDidMount() {
    
    var lan = localStorage.getItem("lang");
    //alert(lan);
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const token = localStorage.getItem("professional-token");
        if(token==null)
        {
          window.location.href = "/professional-logout";
        }
        const ru = jwtDecode(token);
        console.log('test=>'+ru);
        const exp_time = (Math.floor(Date.now()/1000))-ru.iat;
        if(exp_time>3600)
        {
          window.location.href = "/professional-logout";
        }
    
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      //alert('ssss'+res.data);
      this.setState({
        prof: res.data,
        d_reason:res.data.disapproval_reason
       });
       
    })

    .catch(err =>{
      console.log('Error from professional');
    })
    const p_status = localStorage.getItem("p_status");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    
    
      
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const data = {
      phone: this.state.phone
    };

    axios
      .post('/api/users',data)
      .then(res => {
        this.setState({
          phone: ''
        });
        alert(res.data.msg);
      })
      .catch(err => {
        alert(err.data.msg);
        console.log("Error in CreateBook!");
      })
  };

  

  render() {
    /* const books = this.state.books;
    console.log("PrintBook: " + books);
    let bookList;

    if(!books) {
      bookList = "there is no book record!";
    } else {
      bookList = books.map((book, k) =>
        <BookCard book={book} key={k} />
      );
    }
*/

    //const d_reason = localStorage.getItem("d_reason");
    return (
      <div>
      <Headertop/>
      
      
      <div className='container-fluid'>
      <div className='row'>
      
        <Header />
        <div className='col-md-10 menu-right'>
        <div className="dashboard-right thanks_msg">
        
                <h3 className="lang">Registration Denied!</h3>
<h5 className="lang">Your Registration is Denied by the admin because of the Following Reason:<br/> {this.state.d_reason}</h5>
<Link to="/update-professional-profile" className="btn btn-primary lang">Update Details</Link>
          </div>
          
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default DisapproveProfessional;